.post {
    display: block;
    max-width: 960px;
    text-align: center;
    margin: 10px auto;
    padding-bottom: 200px;
}

.title {
    font-weight: bold;
}

.post-content {
    text-align: left;
    margin-top: 40px;
    padding-inline: 15%;
    font-size: 1.1em;
}

.post-content img {
    max-width: 100%;
    height: auto;
}

.videos, iframe, .videos iframe {
    width: 100%;
    aspect-ratio: 16 / 9;
}