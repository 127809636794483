.previeweditor {
    max-width: 100%;
    padding: 10px 10px;
    box-sizing: border-box;
    gap: 3%;
    display: flex;
    justify-content: space-between;
    text-align: center;
}

@media all and (max-width:550px) {
    .previeweditor {
        max-width: 100%;
        padding: 10px 10px;
        box-sizing: border-box;
        height: 90%;
        display: block;
        text-align: center;
    }
}
.preview {
    flex: 1;
    margin: 20px auto;
    padding: 40px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.2s;
    min-width: 30%;
    max-height: 360px;
    text-align: left;
    overflow-y: scroll;
    overflow-wrap: break-word;
}
@media all and (max-width:550px) {
    .preview {
        max-width: 100%;
        padding: 20px 10%;
        box-sizing: border-box;
        max-height: 45%;
        display: block;
        overflow-y: scroll;
    }
}
.preview h2 {
    text-align: center;
    margin-top: 0px;
    margin-bottom: 5px;
}

.editor {
    min-width: 40%;
    max-width: 50%;
    padding: 20px 5px;
    box-sizing: border-box;
    height: 100%;
    /* border-style: solid; */
    margin: 20px auto;
}
@media all and (max-width:550px) {
    .editor {
        max-width: 100%;
        padding: 10px 10px;
        height: 90%;
        display: block;
        text-align: center;
    }
}

.editor label {
    display: block;
    margin: 20px auto;
}

.editor input {
    font-size: 1em;
    color: #777;
    width: 80%;
    margin-bottom: 15px;
}
.quill {
    margin-top: 10px;
}
.submit-button {
    margin-top: 10px;
    text-align: right;
}