@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

/* base styles */
body {
  font-family: Poppins, sans-serif;
  margin: 0;
}

.btn {
  background: none;
  border: 2px solid #1f9751;
  padding: 6px 12px;
  border-radius: 4px;
  color: #1f9751;
  font-weight: bold;
  cursor: pointer;
  font-size: 1em;
}
.btn:hover {
  background: #1f9751;
  color: #fff;
}
.ql-editor {
  height: 250px;
  min-height: 250px;
  min-width: 300px;
  overflow: auto;
}